const TEST_CASE = {
  LOGIN: {
    invalidParams: {
      params: {},
      response: { token: null, user: null, message: 'invalidParams' },
    },
    invalidCredentials: {
      params: { email: 'invalid_email@gmail.com' },
      response: {
        token: null,
        user: null,
        message: 'invalidCredentials',
      },
    },
    expiredPassword: {
      params: { email: 'expired_password@gmail.com' },
      response: {
        error: 'expired_password',
      },
    },
    success: {
      params: { email: 'success_email@gmail.com' },
      response: {
        token: 'token',
        user: { id: 1, modules: [{ name: 'vault', entrypointPath: '/vault' }] },
        message: null,
      },
    },
  },
  RESET_PASSWORD: {
    notFound: {
      email: 'asdfg@gmai.com',
    },
    alreadyRequested: {
      email: 'alreadyRequested@gmail.com',
    },
    serverErrorCode: {
      code: '222222',
    },
    validCode: {
      code: '123456',
    },
    expiredCode: {
      code: '999999',
    },
    validEmail: {
      email: 'success_email@gmail.com',
    },
    invalidPassword: {
      password: 'invalid_password',
    },
    validPassword: {
      password: 'valid_password',
    },
  },
};

export default TEST_CASE;
