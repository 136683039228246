import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useIdentityContext } from '../IdentityContext';
import { useAxiosContext } from '../AxiosContext';
import useLogout from '../components/logoutButton/useLogout';

const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts && parts.length === 2) return parts.pop()?.split(';').shift();

  return undefined;
};

function setCookie(cname: string, cvalue: string, exdays: number) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

/**
 * Make sure than the user is authenticated. If not, we redirect to the login page
 * @param Component
 */
const withToken = (Component: React.ComponentType<unknown>) => {
  // eslint-disable-next-line func-names
  return function () {
    const [searchParams, _] = useSearchParams();

    const redirectUrl = searchParams.get('redirect_url');
    if (redirectUrl) {
      sessionStorage.setItem('redirectUrl', redirectUrl);
    }

    const { hasPendingCalls } = useAxiosContext();
    const { submitLogout } = useLogout();
    const { token, setIdentity } = useIdentityContext();
    // Connection to a broadcast channel
    const bc = new BroadcastChannel('identity_channel');

    const getTokenFromSessionStorage = () => {
      const sessionToken = sessionStorage.getItem('identity');
      if (!sessionToken) return undefined;

      return JSON.parse(sessionToken).token;
    };

    useEffect(() => {
      const userIdentityCookie = getCookie('user_identity');
      const sessionToken = getTokenFromSessionStorage();
      // A handler that listen to events from other browser tabs
      bc.onmessage = (event) => {
        setIdentity(event.data);
        if (event.data.token) {
          sessionStorage.setItem('identity', JSON.stringify(event.data));
          setCookie('user_identity', JSON.stringify(event.data), 1);
        } else {
          sessionStorage.removeItem('identity');
          setCookie('user_identity', JSON.stringify(event.data), -1);
        }
      };

      // create session storage and identity from cookie
      // to keep the user logged in on new browser tab
      if (userIdentityCookie && !sessionToken) {
        setIdentity(JSON.parse(userIdentityCookie));
        sessionStorage.setItem('identity', userIdentityCookie);
      }
      return () => {
        // Disconnect the channel
        bc.close();
      };
    }, []);

    useEffect(() => {
      const identityCookie = getCookie('identity');
      const sessionToken = getTokenFromSessionStorage();

      if (token) {
        if (
          token !== identityCookie ||
          token !== sessionToken ||
          identityCookie !== sessionToken
        ) {
          submitLogout();
        }
      }
    }, [hasPendingCalls]);

    return <Component />;
  };
};

export default withToken;
