import React from 'react';
import { Box } from '@mui/material';
import { IconNames, Tiles } from '@codeboxxtechschool/ginza_shared_components';
import { useIdentityContext } from '../../IdentityContext';
import CenteredContainer from '../../components/centeredContainer';
import { useAxiosContext } from '../../AxiosContext';
import { getModuleCallback } from '../../http/getModuleCallback';
import useEbaySession from './hooks/useEbaySession';

function ModulesSelector(): JSX.Element {
  const { user, token } = useIdentityContext();
  const { setHasPendingCalls } = useAxiosContext();
  const { submitEbaySession } = useEbaySession();

  const redirect = (entrypointPath: string) => {
    window.location.href = entrypointPath;
  };

  // For safety reasons, eBay advised us to redirect using a post with token in the body instead of a get with token in params.
  function postForm(path: string, params: { data: string }) {
    const method = 'post';

    const form = document.createElement('form');
    form.setAttribute('method', method);
    form.setAttribute('action', path);

    const hiddenField = document.createElement('input');
    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', 'token');
    hiddenField.setAttribute('value', params.data);

    form.appendChild(hiddenField);

    document.body.appendChild(form);
    form.submit();
  }

  const handleOnClick =
    (
      entrypointPath: string,
      callback: string,
      destination: string,
      name: string
    ) =>
    async () => {
      switch (destination) {
        case 'ebay':
          submitEbaySession(name).then((res) => {
            if (res) {
              postForm(res.url, {
                data: res.token,
              });
            }
          });
          break;
        default:
          // currently the only other destination is 'gcp', using default for this case
          if (callback) {
            const { status } = await getModuleCallback(`${callback}`, {
              authorization: `Bearer ${token}`,
              'X-Requested-With': 'XMLHttpRequest',
            });
            if (status && status === 200) redirect(entrypointPath);
          } else {
            redirect(entrypointPath);
          }

          setHasPendingCalls((prevState) => !prevState);
      }
    };

  const getIconName = (name: string): IconNames => {
    switch (name) {
      case 'cargo':
      case 'vault':
        return 'vault';
      case 'go_to_dev':
        return 'developerMode';
      case 'rails_admin':
        return 'admin';
      case 'photo_catalog':
        return 'album';
      default:
        return 'auth';
    }
  };

  return (
    <Box>
      <CenteredContainer>
        <Tiles>
          {(user?.modules || []).map((module) => {
            const { name, entrypointPath, callback, destination } = module;

            const icon = getIconName(name);

            return (
              <div id={`${name}-button`} key={name}>
                <Tiles.Tile
                  onClick={handleOnClick(
                    entrypointPath,
                    callback,
                    destination,
                    name
                  )}
                >
                  <Tiles.Tile.Icon name={icon} />
                  <Tiles.Tile.Name>{name.replace('_', ' ')}</Tiles.Tile.Name>
                </Tiles.Tile>
              </div>
            );
          })}
        </Tiles>
      </CenteredContainer>
    </Box>
  );
}

export default ModulesSelector;
