import { COLORS } from '@codeboxxtechschool/ginza_shared_components';
import React from 'react';
import { Box } from '@mui/material';

interface ErrorTextProps {
  text: string | null | undefined;
  show: boolean;
}
function ErrorText({ text, show }: ErrorTextProps): JSX.Element | null {
  if (!show || !text) return null;

  return (
    <Box sx={{ margin: 'auto', maxWidth: 'fit-content' }}>
      <p style={{ color: COLORS.red }}>{text}</p>
    </Box>
  );
}

export default ErrorText;
