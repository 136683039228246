import React, { FC } from 'react';
import {
  ThemeProvider,
  COLORS,
} from '@codeboxxtechschool/ginza_shared_components';
import { Container } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';

import RenderRoutes from './routes/RenderRoutes';
import './i18n/config';

const App: FC = () => {
  return (
    <ThemeProvider>
      <Container
        maxWidth={false}
        sx={{ backgroundColor: COLORS.gray, height: '100vh' }}
      >
        <Router basename={process.env.REACT_APP_WEBAPP_PATH}>
          <RenderRoutes />
        </Router>
      </Container>
    </ThemeProvider>
  );
};

export default App;
