import React, { ReactElement } from 'react';
import {
  COLORS,
  ContentContainer,
  H1,
  LoadingBar,
} from '@codeboxxtechschool/ginza_shared_components';
import { Stack } from '@mui/material';

interface CenterFormProps {
  children?: ReactElement;
  description?: string | ReactElement;
  loading?: boolean;
  title: string;
}
function CenterForm({
  children,
  description,
  loading,
  title,
}: CenterFormProps): JSX.Element {
  return (
    <>
      {loading && (
        <div style={{ margin: '0 1px' }}>
          <LoadingBar />
        </div>
      )}
      <ContentContainer fitContent padding="50px 60px">
        <Stack
          spacing={2}
          alignItems="center"
          sx={{
            backgroundColor: COLORS.white,
            minWidth: '330px',
            maxWidth: '330px',
            textAlign: 'center',
          }}
        >
          <div>
            <H1 fontWeight="bold">{title}</H1>
          </div>
          {description && (
            <div>
              <p style={{ maxWidth: '330px' }}>{description}</p>
            </div>
          )}
          {children}
        </Stack>
      </ContentContainer>
    </>
  );
}

export default CenterForm;
