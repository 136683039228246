import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import Logo from '../components/logo';
import LogoutButton from '../components/logoutButton';
import CenteredContainer from '../components/centeredContainer';

function Pages(): JSX.Element {
  return (
    <CenteredContainer>
      <div>
        <LogoutButton />
        <Box
          sx={{ display: 'flex', justifyContent: 'center', marginBottom: 5 }}
        >
          <Logo />
        </Box>
        <Outlet />
      </div>
    </CenteredContainer>
  );
}

export default Pages;
