import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import postEbaySession from '../../../http/postEbaySession';
import { useAxiosContext } from '../../../AxiosContext';
import { useIdentityContext } from '../../../IdentityContext';

interface EbaySessionResponseInterface {
  url: string;
  token: string;
}

const useEbaySession = () => {
  const { setHasPendingCalls } = useAxiosContext();
  const { setSnackBarProperties, resetSnackBarProperties } =
    useIdentityContext();
  const { t } = useTranslation('general');

  const submitEbaySession = async (
    intent: string
  ): Promise<EbaySessionResponseInterface | null> => {
    return postEbaySession(intent).then((response: AxiosResponse) => {
      setHasPendingCalls((prevState) => !prevState);
      if (response?.data?.url && response?.data?.token) {
        return { url: response.data.url, token: response.data.token };
      }
      const errorMessage = response.data?.error?.message || response;
      setSnackBarProperties({
        message: t(`errors.${errorMessage}`),
        visibility: true,
        severity: 'error',
        handleOnClose: resetSnackBarProperties,
      });

      return null;
    });
  };

  return {
    submitEbaySession,
  };
};

export default useEbaySession;
