import { rest, RestRequest } from 'msw';
import TEST_CASE from '../testCases';
import { PostUpdatePasswordBody } from '../../http/postPasswordUpdate';

export default rest.post(
  '/updatePassword',
  (req: RestRequest<PostUpdatePasswordBody>, res, ctx) => {
    const {
      body: { newPassword },
    } = req;

    if (newPassword === TEST_CASE.RESET_PASSWORD.validPassword.password) {
      return res(ctx.status(204), ctx.json({}));
    }

    if (newPassword === TEST_CASE.RESET_PASSWORD.invalidPassword.password) {
      return res(ctx.status(400), ctx.json({}));
    }

    return res(ctx.status(500), ctx.json({}));
  }
);
