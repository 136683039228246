import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import {
  Button,
  ButtonLink,
} from '@codeboxxtechschool/ginza_shared_components';
import { useNavigate } from 'react-router-dom';
import CenterForm from '../../components/centerForm';
import { LOGIN_ROUTES, RESET_PASSWORD_ROUTES } from '../../routes/constants';
import useNewPasswords from './hooks/useNewPasswords';
import ErrorText from '../../components/errorText';
import PasswordField from '../../components/passwordField';
import { useIdentityContext } from '../../IdentityContext';

function PasswordsForm(): JSX.Element {
  const { t } = useTranslation(['resetPassword', 'general']);
  const { token } = useIdentityContext();
  const navigate = useNavigate();
  const {
    setNewPasswords,
    newPasswords,
    submit,
    validations,
    setValidations,
    isValid,
    loading,
  } = useNewPasswords();

  const handleOnChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target;
    setNewPasswords({
      ...newPasswords,
      [name]: value,
    });
  };

  const handleOnLinkClick = () => {
    navigate(LOGIN_ROUTES.login, { replace: true });
  };

  const handleSubmit = async () => {
    if (isValid()) {
      const { status, message } = await submit();
      if (status === 204) {
        localStorage.removeItem('email');
        localStorage.removeItem('resetToken');
        if (token) sessionStorage.removeItem('identity');

        navigate(
          RESET_PASSWORD_ROUTES.resetPassword + RESET_PASSWORD_ROUTES.success,
          { replace: true }
        );
      } else {
        setValidations((prevState) => ({
          ...prevState,
          submit: {
            error: true,
            message: message || t('resetPassword:error.invalidRequest'),
          },
        }));
      }
    }
  };

  const { password, passwordConfirmation } = newPasswords;

  return (
    <CenterForm
      title={t('resetPassword:title')}
      description={t('resetPassword:description')}
      loading={loading}
    >
      <>
        <ErrorText
          text={validations.submit.message}
          show={validations.submit.error}
        />
        <div style={{ width: '100%' }}>
          <PasswordField
            label={t('general:inputs.newPassword')}
            onChange={handleOnChange}
            validation={validations.newPassword}
            value={password}
          />
        </div>
        <div style={{ width: '100%' }}>
          <PasswordField
            label={t('general:inputs.newPasswordConfirmation')}
            name="passwordConfirmation"
            onChange={handleOnChange}
            validation={validations.passwordConfirmation}
            value={passwordConfirmation}
          />
        </div>
        <div style={{ width: '100%' }}>
          <Button fullWidth onClick={handleSubmit} disabled={loading}>
            {t('resetPassword:buttons.resetPassword')}
          </Button>
        </div>
        <div style={{ width: '100%' }}>
          <Box sx={{ margin: 2, textAlign: 'center' }}>
            <ButtonLink
              onClick={handleOnLinkClick}
              text={t('resetPassword:links.signIn')}
            />
          </Box>
        </div>
      </>
    </CenterForm>
  );
}

export default PasswordsForm;
