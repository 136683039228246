import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonLink } from '@codeboxxtechschool/ginza_shared_components';

const GoBackButton = ({ handleOnClick }: { handleOnClick: () => void }) => {
  const { t } = useTranslation('resetPassword');

  return <ButtonLink onClick={handleOnClick} text={t('links.goBack')} />;
};

export default GoBackButton;
