import { useEffect, useRef, useState } from 'react';

export default function useTimer(
  numberOfSeconds: number,
  callback?: () => void,
  start = true
) {
  const [isOn, setIsOn] = useState(start);
  const [timer, setTimer] = useState(numberOfSeconds);
  const id = useRef<number | undefined>(undefined);
  const clear = () => {
    window.clearInterval(id.current);
  };

  useEffect(() => {
    if (isOn) {
      id.current = window.setInterval(() => {
        setTimer((time) => time - 1);
      }, 1000);
    }
    return () => clear();
  }, [isOn]);

  useEffect(() => {
    if (timer === 0) {
      clear();
      if (callback) callback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  const startTimer = () => {
    setIsOn(true);
  };

  return { timer, startTimer };
}
