import React, { lazy } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { Loading } from '@codeboxxtechschool/ginza_shared_components';
import { RESET_PASSWORD_ROUTES } from '../../routes/constants';
import CenterForm from '../../components/centerForm';

const EmailForm = lazy(() => import('./EmailForm'));
const VerificationCodeForm = lazy(() => import('./VerificationCodeForm'));
const NewPasswordsForm = lazy(() => import('./PasswordsForm'));
const Success = lazy(() => import('./Success'));

function ResetPassword(): JSX.Element {
  return (
    <React.Suspense
      fallback={
        <CenterForm title="" description="">
          <Loading />
        </CenterForm>
      }
    >
      <Routes>
        <Route path="" element={<Outlet />}>
          <Route path="" element={<EmailForm />} />
          <Route
            path={RESET_PASSWORD_ROUTES.verification}
            element={<VerificationCodeForm />}
          />
          <Route
            path={RESET_PASSWORD_ROUTES.newPassword}
            element={<NewPasswordsForm />}
          />
          <Route path={RESET_PASSWORD_ROUTES.success} element={<Success />} />
        </Route>
      </Routes>
    </React.Suspense>
  );
}

export default ResetPassword;
