import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValidation } from '../../login/interfaces';
import { postVerifyEmail } from '../../../http/postVerifyEmail';
import { useIdentityContext } from '../../../IdentityContext';
import validateEmail from '../../../utils/email';

interface SubmittedEmailResponse {
  message: string | undefined;
}
export const initialValidation: FieldValidation = {
  error: false,
  message: '',
};

const useValidateEmail = () => {
  const { user } = useIdentityContext();
  const { t } = useTranslation('resetPassword');

  const [email, setEmail] = useState(
    user?.email || localStorage.getItem('email') || ''
  );

  const [validation, setValidation] =
    useState<FieldValidation>(initialValidation);
  const [loading, setLoading] = useState(false);

  const isValid = (): boolean => {
    let valid = true;
    let newValidation: FieldValidation = { ...initialValidation };

    if (email.trim().length === 0) {
      newValidation = {
        error: true,
        message: t('inputs.error.enterEmail'),
      };
      valid = false;
    } else if (!validateEmail(email.trim())) {
      newValidation = {
        error: true,
        message: t('inputs.error.emailFormat'),
      };
      valid = false;
    }

    setValidation(newValidation);
    setLoading(valid);
    return valid;
  };

  const submitEmail = async (): Promise<SubmittedEmailResponse> => {
    return postVerifyEmail({ user: { email } })
      .then<SubmittedEmailResponse>((response) => {
        let message: string | undefined;
        const { status } = response;
        if (status !== 200) message = t('error.invalidRequest');
        if (status === 404) message = t('error.invalidEmail');

        return {
          message,
        };
      })
      .catch((reason) => {
        let message = t('error.invalidRequest');
        const {
          response: { status },
        } = reason;
        if (status === 404) message = t('error.invalidEmail');

        return {
          message,
        };
      })
      .finally(() => setLoading(false));
  };

  return {
    email,
    setEmail,
    validation,
    setValidation,
    submitEmail,
    isValid,
    loading,
  };
};

export default useValidateEmail;
