import { useEffect, useRef, useState } from 'react';

export default function useAutoIncrement(maximumValue: number) {
  const [value, setValue] = useState(1);
  const id = useRef<number | undefined>(undefined);
  const clear = () => {
    window.clearInterval(id.current);
  };

  useEffect(() => {
    id.current = window.setInterval(() => {
      if (value === maximumValue) {
        setValue(1);
      } else {
        setValue((v) => v + 1);
      }
    }, 300);
    return () => clear();
  }, [maximumValue, value]);

  return { value };
}
