import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValidation } from '../../login/interfaces';
import {
  postPasswordUpdate,
  SubmittedResponse,
} from '../../../http/postPasswordUpdate';

export interface NewPasswords {
  password: string;
  passwordConfirmation: string;
}

export interface PasswordsValidation {
  newPassword: FieldValidation;
  passwordConfirmation: FieldValidation;
  submit: FieldValidation;
}

const initialPasswordsValidation: PasswordsValidation = {
  passwordConfirmation: { message: '', error: false },
  newPassword: { message: '', error: false },
  submit: { message: '', error: false },
};

const useNewPasswords = () => {
  const { t } = useTranslation('resetPassword');

  const [newPasswords, setNewPasswords] = useState<NewPasswords>({
    passwordConfirmation: '',
    password: '',
  });
  const [validations, setValidations] = useState<PasswordsValidation>(
    initialPasswordsValidation
  );
  const [loading, setLoading] = useState(false);

  const isValid = (): boolean => {
    let valid = true;
    let newValidations = { ...initialPasswordsValidation };
    const { passwordConfirmation, password } = newPasswords;

    if (password.trim().length === 0) {
      valid = false;
      newValidations = {
        ...newValidations,
        newPassword: { message: t('inputs.error.newPassword'), error: true },
      };
    }

    if (password.length > 0 && passwordConfirmation !== password) {
      valid = false;
      newValidations = {
        ...newValidations,
        passwordConfirmation: {
          message: t('inputs.error.confirmationNotMatching'),
          error: true,
        },
      };
    }
    setValidations(newValidations);
    setLoading(valid);
    return valid;
  };

  const submit = async (): Promise<SubmittedResponse> => {
    return postPasswordUpdate({
      newPassword: newPasswords.password,
      newPasswordConfirmation: newPasswords.passwordConfirmation,
      reset_password_token: localStorage.getItem('resetToken') as string,
    })
      .then((response) => {
        return response;
      })
      .catch((reason) => {
        return { status: reason.status };
      })
      .finally(() => setLoading(false));
  };

  return {
    newPasswords,
    setNewPasswords,
    validations,
    setValidations,
    submit,
    isValid,
    loading,
  };
};

export default useNewPasswords;
