import { AxiosResponse } from 'axios';
import { postRequest } from './httpClient';

export interface PostUpdatePasswordBody {
  reset_password_token: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

export interface UpdatePasswordResponse {
  message?: { password: string[] };
}

export interface SubmittedResponse {
  message?: string;
  status: number;
}

export const postPasswordUpdate = async (
  payload: PostUpdatePasswordBody
): Promise<SubmittedResponse> => {
  return postRequest<UpdatePasswordResponse>(`/updatePassword`, payload)
    .then((response: AxiosResponse<UpdatePasswordResponse>) => {
      const {
        data: { message },
      } = response;

      let validations: string[] = [];
      if (message) validations = message.password;

      return {
        message: validations[0],
        status: response.status,
      };
    })
    .catch((reason) => {
      const {
        response: { status },
      } = reason;
      return {
        status,
      };
    });
};
