import { AxiosResponse } from 'axios';
import { postRequest } from './httpClient';

export interface PostVerifyOtpBody {
  user: {
    email: string;
    verification: string;
  };
}

export interface PostVerifyOtpResponse {
  token: string | null;
  status: number;
}

export const postVerifyOtp = (
  payload: PostVerifyOtpBody
): Promise<PostVerifyOtpResponse> => {
  return postRequest(`/verifyOtp`, payload)
    .catch((error) => {
      return error.response;
    })
    .then((response: AxiosResponse<PostVerifyOtpResponse>) => {
      return {
        status: response.status,
        token: response.data.token,
      };
    });
};
