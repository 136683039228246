import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonLink,
} from '@codeboxxtechschool/ginza_shared_components';
import { useNavigate } from 'react-router-dom';
import CenterForm from '../../components/centerForm';
import { LOGIN_ROUTES, RESET_PASSWORD_ROUTES } from '../../routes/constants';
import useValidateEmail, { initialValidation } from './hooks/useValidateEmail';
import EmailField from '../../components/emailField';

function EmailForm(): JSX.Element {
  const { t } = useTranslation('resetPassword');
  const navigate = useNavigate();
  const {
    email,
    setEmail,
    validation,
    setValidation,
    submitEmail,
    isValid,
    loading,
  } = useValidateEmail();

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (validation.error) {
      setValidation(initialValidation);
    }

    const {
      target: { value },
    } = event;

    setEmail(value);
  };

  const handleOnLinkClick = () => {
    navigate(LOGIN_ROUTES.login, { replace: true });
  };

  const handleSubmit = async () => {
    if (isValid()) {
      const response = await submitEmail();
      const { message } = response;

      if (message) {
        setValidation({ message, error: true });
      } else {
        localStorage.setItem('email', email);
        navigate(
          RESET_PASSWORD_ROUTES.resetPassword +
            RESET_PASSWORD_ROUTES.verification,
          { replace: true }
        );
      }
    }
  };

  return (
    <CenterForm
      title={t('enterEmailTitle')}
      description={t('enterEmailDescription')}
      loading={loading}
    >
      <>
        <div style={{ width: '100%' }}>
          <EmailField
            value={email}
            validation={validation}
            onChange={handleOnChange}
          />
        </div>
        <div style={{ width: '100%' }}>
          <Button fullWidth onClick={handleSubmit} disabled={loading}>
            {t('buttons.enterEmail')}
          </Button>
        </div>

        <div style={{ width: '100%' }}>
          <ButtonLink onClick={handleOnLinkClick} text={t('links.signIn')} />
        </div>
      </>
    </CenterForm>
  );
}

export default EmailForm;
