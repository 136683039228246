import { AxiosResponse } from 'axios';
import { postRequest } from './httpClient';

export const postLogout = async (): Promise<AxiosResponse> => {
  return postRequest('/logout', {})
    .catch((error) => {
      return error.response;
    })
    .then((response: AxiosResponse) => {
      return response;
    });
};
