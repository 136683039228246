import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import apiValidations from './locales/en/apiValidations.json';
import general from './locales/en/general.json';
import login from './locales/en/login.json';
import logout from './locales/en/logout.json';
import resetPassword from './locales/en/resetPassword.json';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  resources: {
    en: {
      apiValidations,
      general,
      login,
      logout,
      resetPassword,
    },
  },
  ns: ['general'],
});

i18n.languages = ['en'];

export default i18n;
