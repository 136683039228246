import React, {
  createContext,
  Dispatch,
  ReactElement,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import { AlertColor } from '@mui/material';

interface GinzaModule {
  name: string;
  entrypointPath: string;
  callback: string;
  destination: string;
}

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  passwordChangedAt: string;
  modules: Array<GinzaModule>;
}

export interface IdentityState {
  token: string | null;
  user: User | null;
  error: string | null;
}

export interface SnackBarPropertiesState {
  message: string;
  visibility: boolean;
  severity: AlertColor | undefined;
  handleOnClose: () => void;
}

export interface ContextState extends IdentityState {
  setIdentity: Dispatch<SetStateAction<IdentityState>>;
  setSnackBarProperties: Dispatch<SetStateAction<SnackBarPropertiesState>>;
  resetSnackBarProperties: () => void;
  snackBarProperties: SnackBarPropertiesState;
}

export const IdentityContext = createContext({} as ContextState);

function IdentityProvider({
  children,
}: {
  children: ReactElement;
}): JSX.Element {
  const storageItem = sessionStorage.getItem('identity');

  const storedIdentity: IdentityState = storageItem
    ? JSON.parse(storageItem)
    : null;

  const [identity, setIdentity] = useState<IdentityState>({
    token: storedIdentity?.token,
    user: storedIdentity?.user,
    error: storedIdentity?.error,
  });

  const [snackBarProperties, setSnackBarProperties] =
    useState<SnackBarPropertiesState>({
      message: '',
      visibility: false,
      severity: undefined,
      handleOnClose: () => undefined,
    });

  const resetSnackBarProperties = () => {
    setSnackBarProperties((snackBar) => ({
      message: '',
      visibility: false,
      severity: snackBar.severity,
      handleOnClose: () => undefined,
    }));
  };

  const { token, user, error } = identity;

  const value = useMemo(
    () => ({
      token,
      user,
      error,
      setIdentity,
      snackBarProperties,
      setSnackBarProperties,
      resetSnackBarProperties,
    }),
    [error, snackBarProperties, token, user]
  );

  return (
    <IdentityContext.Provider value={value}>
      {children}
    </IdentityContext.Provider>
  );
}

function useIdentityContext(): ContextState {
  const context = React.useContext(IdentityContext);
  if (context === undefined) {
    throw new Error('useIdentityContext must be used within a AppProvider');
  }
  return context;
}

/**
 * Children component
 * @param Component
 */
function withIdentityContext(
  Component: React.ComponentType<unknown>
): React.ComponentType<unknown> {
  return function renderWithContext() {
    return (
      <IdentityProvider>
        <Component />
      </IdentityProvider>
    );
  };
}

export { IdentityProvider, useIdentityContext, withIdentityContext };
