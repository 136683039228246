import React, { FC, ReactElement } from 'react';
import { Box } from '@mui/material';

export const CenteredContainer: FC<{
  children: ReactElement | ReactElement[];
}> = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      {children}
    </Box>
  );
};

export default CenteredContainer;
