import { rest, RestRequest } from 'msw';
import TEST_CASE from '../testCases';
import { PostVerifyOtpBody } from '../../http/postVerifyOtp';

export default rest.post(
  '/verifyOtp',
  (req: RestRequest<PostVerifyOtpBody>, res, ctx) => {
    const {
      body: {
        user: { verification },
      },
    } = req;

    if (verification === TEST_CASE.RESET_PASSWORD.expiredCode.code) {
      return res(
        ctx.status(404),
        ctx.json({ code: 'INVALID_VERIFICATION_CODE' })
      );
    }

    if (verification === TEST_CASE.RESET_PASSWORD.serverErrorCode.code) {
      return res(ctx.status(400));
    }

    return res(ctx.status(200), ctx.json({ token: '123456' }));
  }
);
