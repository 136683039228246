import React from 'react';
import {
  COLORS,
  ContentContainer,
  Icon,
  H1,
  Button,
} from '@codeboxxtechschool/ginza_shared_components';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { LOGIN_ROUTES } from '../../routes/constants';

function Success(): JSX.Element {
  const { t } = useTranslation('resetPassword');

  const handleOnClick = () => {
    window.location.href =
      process.env.REACT_APP_WEBAPP_PATH + LOGIN_ROUTES.login;
  };

  return (
    <ContentContainer fitContent padding="50px 60px">
      <Stack
        spacing={5}
        alignItems="center"
        sx={{
          backgroundColor: COLORS.white,
          minWidth: '330px',
          minHeight: '280px',
          textAlign: 'center',
        }}
      >
        <>
          <div>
            <Icon name="check" size="large" />
          </div>
          <div style={{ width: '100%' }}>
            <H1 fontWeight="bold">{t('successTitle')}</H1>
          </div>
          <div style={{ width: '100%' }}>
            <Button fullWidth onClick={handleOnClick}>
              {t('buttons.login')}
            </Button>
          </div>
        </>
      </Stack>
    </ContentContainer>
  );
}

export default Success;
