import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { worker } from './mocks/browser';
import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV === 'development' && process.env.MOCKS === 'enabled') {
  worker.start();
}

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
