import React, {
  createContext,
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  useState,
} from 'react';
import { AxiosError } from 'axios';
import PropTypes from 'prop-types';

interface AxiosContextState {
  error: AxiosError | undefined;
  setError: Dispatch<SetStateAction<AxiosError | undefined>>;
  hasPendingCalls: boolean;
  setHasPendingCalls: Dispatch<SetStateAction<boolean>>;
}

export const AxiosContext = createContext({} as AxiosContextState);

const AxiosContextProvider: FC<{
  children: ReactElement;
}> = ({ children }): JSX.Element => {
  const [error, setError] = useState<AxiosError>();
  const [hasPendingCalls, setHasPendingCalls] = useState<boolean>(false);

  const value: AxiosContextState = {
    error,
    hasPendingCalls,
    setHasPendingCalls,
    setError,
  };

  return (
    <AxiosContext.Provider value={value}>{children}</AxiosContext.Provider>
  );
};

function useAxiosContext(): AxiosContextState {
  const context = React.useContext(AxiosContext);
  if (context === undefined) {
    throw new Error('useAxiosContext must be used within a AxiosProvider');
  }
  return context;
}

/**
 * Children component
 * @param Component
 */
function withAxiosContext(
  Component: React.ComponentType<unknown>
): React.ComponentType<unknown> {
  return () => {
    return (
      <AxiosContextProvider>
        <Component />
      </AxiosContextProvider>
    );
  };
}

export { AxiosContextProvider, useAxiosContext, withAxiosContext };
