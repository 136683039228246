import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import {
  Button,
  ButtonLink,
  VerificationCodeInput,
} from '@codeboxxtechschool/ginza_shared_components';
import useVerificationCode, {
  initialValidation as initialCodeValidation,
} from './hooks/useVerificationCode';
import { postVerifyEmail } from '../../http/postVerifyEmail';
import { RESET_PASSWORD_ROUTES } from '../../routes/constants';
import { FieldValidation } from '../login/interfaces';
import CenterForm from '../../components/centerForm';
import ErrorText from '../../components/errorText';
import { useIdentityContext } from '../../IdentityContext';
import GoBackButton from '../../components/goBackButton';

function DescriptionWithEmail({
  text,
  email,
}: {
  text: string;
  email: string;
}) {
  return (
    <>
      {text}
      <b>{email}</b>
    </>
  );
}

function VerificationCodeForm(): JSX.Element {
  const { t } = useTranslation('resetPassword');
  const navigate = useNavigate();
  const { user } = useIdentityContext();

  const email = user?.email || (localStorage.getItem('email') as string);
  const {
    code,
    setCode,
    validation,
    setValidation,
    submitVerification,
    isValid,
    loading,
  } = useVerificationCode();
  const [resendValidation, setResendValidation] = useState<FieldValidation>({
    message: '',
    error: false,
  });

  const handleOnChange = (data: string) => {
    if (validation.error) setValidation({ ...initialCodeValidation });

    setCode(data);
  };

  const handleOnLinkClick = async () => {
    postVerifyEmail({ user: { email } }).then((response) => {
      const { status } = response;
      if (status !== 200)
        setResendValidation({
          message: t('error.invalidRequest'),
          error: true,
        });
    });
  };

  const handleOnGoBackClick = () => {
    navigate(RESET_PASSWORD_ROUTES.resetPassword, { replace: true });
  };

  const handleOnClick = async () => {
    if (isValid()) {
      const { message } = await submitVerification(email);
      if (message) {
        setValidation({ message, error: true });
      } else {
        navigate(
          RESET_PASSWORD_ROUTES.resetPassword +
            RESET_PASSWORD_ROUTES.newPassword,
          { replace: true }
        );
      }
    }
  };

  return (
    <CenterForm
      description={
        <>
          <DescriptionWithEmail
            email={email}
            text={t('verificationDescription')}
          />
          <div style={{ width: '100%', marginTop: '20px' }}>
            <GoBackButton handleOnClick={handleOnGoBackClick} />
          </div>
        </>
      }
      loading={loading}
      title={t('verificationTitle')}
    >
      <>
        <ErrorText
          text={resendValidation.message}
          show={resendValidation.error}
        />
        <div>
          <VerificationCodeInput
            onChange={handleOnChange}
            error={validation.error}
            errorText={validation.message}
            value={code}
            underline={false}
          />
        </div>
        <div style={{ width: '100%' }}>
          <Button fullWidth onClick={handleOnClick} disabled={loading}>
            {t('buttons.verify')}
          </Button>
        </div>
        <div style={{ width: '100%' }}>
          <ButtonLink
            onClick={handleOnLinkClick}
            text={t('links.sendCodeAgain')}
          />
        </div>
      </>
    </CenterForm>
  );
}

export default VerificationCodeForm;
