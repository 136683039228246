import { rest, RestRequest } from 'msw';
import TEST_CASE from '../testCases';
import { PostVerifyEmailBody } from '../../http/postVerifyEmail';

export default rest.post(
  '/verifyEmail',
  (req: RestRequest<PostVerifyEmailBody>, res, ctx) => {
    const {
      body: {
        user: { email },
      },
    } = req;

    if (email === TEST_CASE.RESET_PASSWORD.notFound.email) {
      return res(ctx.status(404), ctx.json({}));
    }

    if (email === TEST_CASE.RESET_PASSWORD.alreadyRequested.email) {
      return res(
        ctx.status(200),
        ctx.json({ code: 'VERIFICATION_CODE_ALREADY_GENERATED' })
      );
    }
    return res(ctx.status(200), ctx.json({}));
  }
);
