import { AxiosResponse } from 'axios';
import { postRequest } from './httpClient';
import { IdentityState } from '../IdentityContext';
import { LoginState } from '../pages/login/interfaces';

export interface LoginResponse extends IdentityState {
  message: string | null;
}

export const postLogin = async (
  login: LoginState
): Promise<AxiosResponse<LoginResponse>> => {
  return postRequest<LoginResponse>(`/login${window.location.search}`, {
    login,
  })
    .catch((error) => {
      return error.response;
    })
    .then((response: AxiosResponse<LoginResponse>) => {
      return response;
    });
};
