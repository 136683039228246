import React from 'react';
import { COLORS, TextField } from '@codeboxxtechschool/ginza_shared_components';
import { useTranslation } from 'react-i18next';
import { FieldValidation } from '../../pages/login/interfaces';

interface EmailFieldProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  validation: FieldValidation;
  value: string;
}
function EmailField({
  onChange,
  validation,
  value,
}: EmailFieldProps): JSX.Element {
  const { t } = useTranslation('general');
  const { error, message } = validation;

  return (
    <TextField
      error={error}
      fullWidth
      helperText={message}
      label={t('inputs.email')}
      name="email"
      onChange={onChange}
      sx={{ background: COLORS.white }}
      value={value}
    />
  );
}

export default EmailField;
