import { AxiosResponse } from 'axios';
import { postRequest } from './httpClient';

export interface PostVerifyEmailBody {
  user: {
    email: string;
  };
}
export interface PostVerifyEmailResponse {
  status: number;
}

export const postVerifyEmail = async (
  payload: PostVerifyEmailBody
): Promise<PostVerifyEmailResponse> => {
  return postRequest<PostVerifyEmailResponse>(`/verifyEmail`, payload)
    .then((response: AxiosResponse<PostVerifyEmailResponse>) => {
      return {
        status: response.status,
      };
    })
    .catch((reason) => {
      return reason.response;
    });
};
