import React from 'react';
import SvgContainer from './index.styles';
import LogoImage from '../../assets/images/logo.svg';

export default function Logo() {
  return (
    <SvgContainer>
      <LogoImage />
    </SvgContainer>
  );
}
