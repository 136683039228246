import React from 'react';
import { COLORS, TextField } from '@codeboxxtechschool/ginza_shared_components';
import { useTranslation } from 'react-i18next';
import { FieldValidation } from '../../pages/login/interfaces';

interface PasswordFieldProps {
  label?: string;
  name?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  validation: FieldValidation;
  value: string;
}
function PasswordField({
  label,
  name = 'password',
  onChange,
  validation,
  value,
}: PasswordFieldProps): JSX.Element {
  const { t } = useTranslation('general');
  const { error, message } = validation;

  const l = label || t('inputs.password');

  return (
    <TextField
      error={error}
      fullWidth
      helperText={message}
      label={l}
      name={name}
      onChange={onChange}
      sx={{ background: COLORS.white }}
      type="password"
      value={value}
    />
  );
}

export default PasswordField;
