import { AxiosResponse } from 'axios';
import { postRequest } from './httpClient';

const postEbaySession = async (intent: string): Promise<AxiosResponse> => {
  return postRequest(`/ebaySession`, {
    intent,
  })
    .catch((error) => {
      return error.response || error.code || 'UnexpectedError';
    })
    .then((response: AxiosResponse) => {
      return response;
    });
};
export default postEbaySession;
