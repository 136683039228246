import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIdentityContext } from '../../../IdentityContext';
import { postLogin } from '../../../http/postLogin';
import { FieldValidation, LoginState, LoginValidation } from '../interfaces';
import { useAxiosContext } from '../../../AxiosContext';
import validateEmail from '../../../utils/email';

const initialLogin: LoginState = { email: '', password: '' };

export const initialFieldValidation: FieldValidation = {
  message: '',
  error: false,
};

export const initialLoginValidation: LoginValidation = {
  email: initialFieldValidation,
  password: initialFieldValidation,
  submit: initialFieldValidation,
};

const useLogin = () => {
  const { t } = useTranslation('login');
  const { setIdentity } = useIdentityContext();
  const { setHasPendingCalls } = useAxiosContext();
  const [login, setLogin] = useState<LoginState>(initialLogin);
  const [loginValidation, setLoginValidation] = useState<LoginValidation>(
    initialLoginValidation
  );

  const { email, password } = login;

  const isValid = (): boolean => {
    let valid = true;
    const validations: LoginValidation = { ...initialLoginValidation };
    if (email.trim().length === 0) {
      validations.email = {
        error: true,
        message: t('inputs.error.email'),
      };
      valid = false;
    } else if (!validateEmail(email.trim())) {
      validations.email = {
        error: true,
        message: t('inputs.error.emailFormat'),
      };
      valid = false;
    }

    if (password.trim().length === 0) {
      validations.password = {
        error: true,
        message: t('inputs.error.password'),
      };
      valid = false;
    }

    setLoginValidation(validations);
    return valid;
  };

  const submitLogin = async () => {
    if (!isValid()) return;

    postLogin(login).then((response) => {
      const { data, status } = response;
      const { token, user } = data;
      const identity = { token, user, error: null };
      // Connection to a broadcast channel
      const bc = new BroadcastChannel('identity_channel');

      const redirectUrl = sessionStorage.getItem('redirectUrl');
      switch (status) {
        case 200:
          sessionStorage.setItem('identity', JSON.stringify(identity));
          setIdentity(identity);
          // Example of sending of a very simple message
          bc.postMessage(identity);
          setHasPendingCalls((prevState) => !prevState);
          if (redirectUrl) {
            sessionStorage.removeItem('redirectUrl');
            const url = new URL(redirectUrl);
            if (process.env.BACKEND_RAILS_ENV === 'development' && token) {
              url.searchParams.append('access_token', token);
              url.searchParams.append('identity_refresh_token', token);
            }
            window.location.href = url.toString();
          }
          break;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        case 400:
          if (data?.error) {
            if (data.error === 'expired_password') {
              response.statusText = 'expiredPassword';
            } else if (data.error === 'account_expired') {
              response.statusText = 'accountExpired';
            } else {
              response.statusText = 'invalidRequest';
            }
          }
        // eslint-disable-next-line no-fallthrough
        default:
          setLoginValidation({
            ...initialLoginValidation,
            submit: {
              error: true,
              message: t(`error.${response.statusText}`),
            },
          });
      }
    });
  };

  return {
    loginValidation,
    login,
    setLogin,
    setLoginValidation,
    submitLogin,
  };
};

export default useLogin;
