import { Box, Stack } from '@mui/material';
import {
  Button,
  COLORS,
  Icon,
  H3,
} from '@codeboxxtechschool/ginza_shared_components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useIdentityContext } from '../../IdentityContext';

export default function LogoutButton() {
  const { token } = useIdentityContext();
  const navigate = useNavigate();

  if (!token) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        right: '60px',
        top: '60px',
      }}
    >
      <Button
        outlined
        borderColor={COLORS.blue}
        backgroundColor="transparent"
        textColor={COLORS.blue}
        onClick={() => {
          navigate('/logout');
        }}
      >
        <Stack spacing={1} direction="row" alignItems="center">
          <Icon name="logout" size="xSmall" />
          <H3 fontSize="bold">Logout</H3>
        </Stack>
      </Button>
    </Box>
  );
}
