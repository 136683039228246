import React from 'react';
import { Alert, Snackbar } from '@mui/material';
import { SnackBarPropertiesState } from '../../IdentityContext';

export function IdentitySnackbar({
  visibility,
  handleOnClose,
  message,
  severity,
}: SnackBarPropertiesState): JSX.Element {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={visibility}
      sx={{ width: '50%' }}
      autoHideDuration={6000}
      onClose={handleOnClose}
      message={message}
      data-testid="identity-snackbar"
    >
      <Alert
        severity={severity}
        sx={{ width: '100%', fontWeight: 700 }}
        data-testid="identity-snackbar-alert"
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export default IdentitySnackbar;
