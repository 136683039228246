import { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { getRequest } from './httpClient';

export const getModuleCallback = async (
  url: string,
  headers: AxiosRequestHeaders
): Promise<AxiosResponse> => {
  return getRequest(url, headers)
    .catch((error) => {
      return error;
    })
    .then((response: AxiosResponse) => {
      return response;
    });
};
