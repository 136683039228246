import React from 'react';
import {
  Button,
  ButtonLink,
} from '@codeboxxtechschool/ginza_shared_components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useLogin, { initialFieldValidation } from './hooks/useLogin';
import CenterForm from '../../components/centerForm';
import ErrorText from '../../components/errorText';
import { useIdentityContext } from '../../IdentityContext';
import EmailField from '../../components/emailField';
import PasswordField from '../../components/passwordField';

function Login(): JSX.Element {
  const { t } = useTranslation('login');
  const navigate = useNavigate();
  const { error } = useIdentityContext();
  const { login, setLogin, submitLogin, loginValidation, setLoginValidation } =
    useLogin();

  const { email, password } = login;
  const {
    email: emailValidation,
    password: passwordValidation,
    submit: submitValidation,
  } = loginValidation;

  const handleOnChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value }: HTMLInputElement = target;

    if (loginValidation[name].error) {
      setLoginValidation({
        ...loginValidation,
        [name]: initialFieldValidation,
      });
    }
    setLogin({
      ...login,
      [name]: value,
    });
  };

  const handleOnLinkClick = () => {
    navigate('/resetPassword');
  };

  return (
    <div>
      <CenterForm description={t('description')} title={t('title')}>
        <>
          <ErrorText
            text={error || submitValidation.message}
            show={!!error || submitValidation.error}
          />
          <div style={{ width: '100%' }}>
            <EmailField
              value={email}
              validation={emailValidation}
              onChange={handleOnChange}
            />
          </div>
          <div style={{ width: '100%' }}>
            <PasswordField
              onChange={handleOnChange}
              validation={passwordValidation}
              value={password}
            />
          </div>
          <div style={{ width: '100%' }}>
            <Button id="login-button" fullWidth onClick={submitLogin}>
              {t('buttons.login')}
            </Button>
          </div>
          <div style={{ width: '100%' }}>
            <ButtonLink
              onClick={handleOnLinkClick}
              text={t('buttons.forgotPassword')}
            />
          </div>
        </>
      </CenterForm>
    </div>
  );
}

export default Login;
