import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValidation } from '../../login/interfaces';
import { postVerifyOtp } from '../../../http/postVerifyOtp';

export const initialValidation: FieldValidation = {
  error: false,
  message: '',
};

interface SubmittedCodeResponse {
  message: string | undefined;
}

const useVerificationCode = () => {
  const { t } = useTranslation('resetPassword');
  const [code, setCode] = useState('');
  const [validation, setValidation] =
    useState<FieldValidation>(initialValidation);
  const [loading, setLoading] = useState(false);

  const isValid = (): boolean => {
    let valid = true;
    let newValidation: FieldValidation = { ...initialValidation };

    if (code.trim().length < 6) {
      newValidation = {
        error: true,
        message: t('inputs.error.enterVerification'),
      };
      valid = false;
    }

    setValidation(newValidation);
    setLoading(valid);
    return valid;
  };

  const submitVerification = async (
    email: string
  ): Promise<SubmittedCodeResponse> => {
    return postVerifyOtp({
      user: { email, verification: code },
    })
      .then<SubmittedCodeResponse>((response) => {
        const { token, status } = response;
        let message: string | undefined;
        if (status !== 200) message = t('error.invalidRequest');
        if (status === 404) message = t('error.invalidCode');

        if (token) localStorage.setItem('resetToken', token);

        return {
          message,
        };
      })
      .catch((reason) => {
        const { status } = reason.response;
        let message: string | undefined;
        if (status !== 200) message = t('error.invalidRequest');
        if (status === 404) message = t('error.invalidCode');

        return {
          message,
        };
      })
      .finally(() => setLoading(false));
  };

  return {
    validation,
    setValidation,
    submitVerification,
    code,
    setCode,
    isValid,
    loading,
  };
};

export default useVerificationCode;
