export interface LoginRoutes {
  login: string;
}

export interface ResetRoutes {
  resetPassword: string;
  verification: string;
  newPassword: string;
  success: string;
}

export const RESET_PASSWORD_ROUTES: ResetRoutes = {
  resetPassword: '/resetPassword',
  verification: '/verification',
  newPassword: '/new',
  success: '/success',
};

export const LOGIN_ROUTES: LoginRoutes = {
  login: '/login',
};
