import { useIdentityContext } from '../../IdentityContext';
import { postLogout } from '../../http/postLogout';
import { useAxiosContext } from '../../AxiosContext';

const useLogout = () => {
  const { setIdentity } = useIdentityContext();
  const { setHasPendingCalls } = useAxiosContext();
  // Connection to a broadcast channel
  const bc = new BroadcastChannel('identity_channel');

  const submitLogout = async (): Promise<boolean> => {
    return postLogout()
      .then(() => {
        setIdentity({ token: null, user: null, error: null });
        sessionStorage.removeItem('identity');
        setHasPendingCalls((prevState) => !prevState);
        // sending of a message to listeners
        bc.postMessage({ token: null, user: null, error: null });
        return true;
      })
      .catch((r) => {
        // eslint-disable-next-line no-console
        console.error(r);
        setIdentity((prevState) => ({ ...prevState, error: r }));
        return false;
      })
      .finally(() => {
        return false;
      });
  };

  return {
    submitLogout,
  };
};

export default useLogout;
