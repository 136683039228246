import React from 'react';

import { Route, Routes, Navigate } from 'react-router-dom';
import { compose } from 'msw';
import ModuleSelector from '../pages/modulesSelector';
import Login from '../pages/login';
import Pages from '../pages';
import { useIdentityContext, withIdentityContext } from '../IdentityContext';
import withToken from '../hoc/withToken';
import { withAxiosContext } from '../AxiosContext';
import Logout from '../pages/logout';
import ResetPassword from '../pages/resetPassword';
import IdentitySnackbar from '../components/identitySnackBar';
import { LOGIN_ROUTES, RESET_PASSWORD_ROUTES } from './constants';

export function RenderRoutes(): JSX.Element {
  const { token, snackBarProperties } = useIdentityContext();

  return (
    <>
      <IdentitySnackbar
        message={snackBarProperties.message}
        visibility={snackBarProperties.visibility}
        severity={snackBarProperties.severity}
        handleOnClose={snackBarProperties.handleOnClose}
      />
      <Routes>
        <Route path="/" element={<Pages />}>
          {!token && (
            <>
              <Route path={LOGIN_ROUTES.login} element={<Login />} />
              <Route
                path="/*"
                element={<Navigate to={LOGIN_ROUTES.login} replace />}
              />
              <Route
                index
                element={<Navigate to={LOGIN_ROUTES.login} replace />}
              />
            </>
          )}
          {token && (
            <>
              <Route path="modules" element={<ModuleSelector />} />
              <Route path="/*" element={<Navigate to="/modules" replace />} />
              <Route index element={<Navigate to="/modules" replace />} />
            </>
          )}
          <Route path="logout" element={<Logout />} />
          <Route
            path={`${RESET_PASSWORD_ROUTES.resetPassword}/*`}
            element={<ResetPassword />}
          />
        </Route>
      </Routes>
    </>
  );
}

export default compose(
  withIdentityContext,
  withAxiosContext,
  withToken
)(RenderRoutes);
