import axios, { AxiosRequestHeaders } from 'axios';

const axiosClient = axios.create({
  headers: {
    'Content-type': 'application/json',
  },
});

axiosClient.defaults.timeout = 10000;

export interface LoginPayload {
  login: {
    email: string;
    password: string;
  };
}

export function postRequest<T>(URL: string, payload: Record<never, never>) {
  const csrf: string =
    document.head
      .getElementsByTagName('meta')
      ?.namedItem('csrf-token')
      ?.getAttribute('content') || 'empty';
  const tag: string =
    document.head
      .getElementsByTagName('meta')
      ?.namedItem('csrf-param')
      ?.getAttribute('content') || 'empty';

  const p = { [tag]: csrf, ...payload };
  return axiosClient.post<T>(`${process.env.REACT_APP_WEBAPP_PATH}${URL}`, p);
}

export function getRequest(url: string, headers: AxiosRequestHeaders) {
  return axiosClient.get(url, { headers });
}

export default axiosClient;
