import { rest, RestRequest } from 'msw';
import TEST_CASE from '../testCases';
import { LoginState } from '../../pages/login/interfaces';

interface LoginPayload {
  login: LoginState;
}
export default rest.post(
  '/login',
  (req: RestRequest<LoginPayload>, res, ctx) => {
    const { body } = req;
    const { login } = body;

    if (Object.keys(req.body).length === 0) {
      return res(
        ctx.status(400),
        ctx.json({ error: TEST_CASE.LOGIN.invalidParams.response })
      );
    }

    const { email } = login;

    if (email === TEST_CASE.LOGIN.invalidCredentials.params.email) {
      return res(
        ctx.status(401),
        ctx.json(TEST_CASE.LOGIN.invalidCredentials.response)
      );
    }

    if (email === TEST_CASE.LOGIN.expiredPassword.params.email) {
      return res(
        ctx.status(400),
        ctx.json(TEST_CASE.LOGIN.expiredPassword.response)
      );
    }

    return res(ctx.status(200), ctx.json(TEST_CASE.LOGIN.success.response));
  }
);
