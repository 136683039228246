import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { ButtonLink, H3 } from '@codeboxxtechschool/ginza_shared_components';
import { times } from 'lodash';
import useLogout from '../../components/logoutButton/useLogout';
import CenterForm from '../../components/centerForm';
import useTimer from '../../hooks/useTimer';
import { useIdentityContext } from '../../IdentityContext';
import useAutoIncrement from '../../hooks/useAutoIncrement';

function Logout(): JSX.Element {
  const [loggedOut, setLoggedOut] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation('logout');
  const { submitLogout } = useLogout();
  const { token } = useIdentityContext();
  const navigateToLogin = () => {
    navigate('/', { replace: true });
  };

  const { timer, startTimer } = useTimer(3, navigateToLogin, false);
  const { value } = useAutoIncrement(3);

  useEffect(() => {
    if (token) {
      submitLogout().then((success) => {
        setLoggedOut(success);
        startTimer();
      });
    } else {
      navigateToLogin();
    }
  }, []);

  return (
    <CenterForm
      description={loggedOut ? t('description', { timer }) : undefined}
      loading={!loggedOut}
      title={t('title')}
    >
      {loggedOut ? (
        <div style={{ width: '100%' }}>
          <ButtonLink
            onClick={navigateToLogin}
            text={t('links.notRedirected')}
          />
        </div>
      ) : (
        <H3> {times(value).map(() => '.')}</H3>
      )}
    </CenterForm>
  );
}

export default Logout as React.FC;
